<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                <span>客户名称：</span>
                            </td>
                            <td >
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入客户名称"
                                       ></el-input>
                            </td>
                            <td style="width: 80px">
                                <span>联系人：</span>
                            </td>
                            <td >
                                <el-input v-model="searchItem.contact" size="small" placeholder="请输入联系人"
                                          ></el-input>
                            </td>
                            <td style="width: 80px">
                                <span>公司类型：</span>
                            </td>
                            <td>
                                <template>
                                    <el-select size="small" v-model="searchItem.companyType"
                                               placeholder="请选择公司类型">
                                        <el-option
                                                v-for="item in types"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                            <td style="width: 80px">
                                <span>客户等级：</span>
                            </td>
                            <td>
                                <template>
                                    <el-select size="small" v-model="searchItem.companyGrade"
                                               placeholder="请选择客户等级">
                                        <el-option
                                                v-for="item in classes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                            <td style="width: 270px;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtu">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button v-if="hasAuthority('info_cust_add')" type="primary"
                                           icon="el-icon-circle-plus" size="small" @click="addCustomer">
                                    增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="companyName" label="客户名称" width="300"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contact" label="联系人" width="100" align="center"></el-table-column>
                            <el-table-column prop="phone" label="手机号码" width="110" align="center"></el-table-column>
                            <el-table-column label="客户等级" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyGrade == 0">A</span>
                                    <span v-if="scope.row.companyGrade == 1">B</span>
                                    <span v-if="scope.row.companyGrade == 2">C</span>
                                    <span v-if="scope.row.companyGrade == 3">D</span>
                                    <span v-if="scope.row.companyGrade == 4">S</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="companyType" label="公司类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-else-if="scope.row.companyType == 1">国企</span>
                                    <span v-else-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-else-if="scope.row.companyType == 3">代理商</span>
                                    <span v-else-if="scope.row.companyType == 4">集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="parent" label="总公司" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="projectName" label="项目名称" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createUserName" label="销售人员" width="120" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="操作" v-if="hasAuthority('info_cust_update')" width="60" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑"
                                           @click="editCustomer(scope.row.id)"></i>
                                    </div>
                                    <!--<el-button @click="deleteCustomer(scope.row)"
                                               type="danger"
                                               icon="el-icon-delete"
                                               style="cursor: pointer"
                                               size="mini">删除
                                    </el-button>-->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog">
            <el-dialog
                    :title="title"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :close-on-click-modal="isClose"
                    center>
                <el-form :model="customerForm" :rules="rules" ref="customerForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-form-item label="客户名称:" prop="companyName">
                        <el-input v-model.trim="customerForm.companyName"></el-input>
                    </el-form-item>
                    <el-form-item label="总公司名称:" prop="parent">
                        <el-input v-model.trim="customerForm.parent"></el-input>
                    </el-form-item>
                    <el-form-item label="公司类型:" prop="companyType">
                        <el-select v-model.trim="customerForm.companyType" style="width:100%;" placeholder="请选择公司类型">
                            <el-option
                                    v-for="item in types"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目名称:" prop="projectName">
                        <el-input v-model.trim="customerForm.projectName"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人:" prop="contact">
                        <el-input v-model.trim="customerForm.contact"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码:" prop="phone">
                        <el-input v-model.number="customerForm.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="客户等级:" style="width:100%;" prop="companyGrade">
                        <el-select v-model.trim="customerForm.companyGrade" placeholder="请选择客户等级">
                            <el-option
                                    v-for="item in classes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveCustomer('customerForm',type)">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    // var userId = localStorage.getItem("userId");
    var userId = 1;

    export default {
        name: "Customer",
        data() {
            return {
                pageList: [16, 20, 30, 50],
                tableHeight: '',
                lineHeight: '',
                isClose: false,
                multipleSelection: [],
                delList: [],
                totalPage: 0,
                // totalPage:30,
                option: {
                    pageIndex: '',
                    pageSize: 16,
                    currentPage: 2
                },
                pageIndex: 1,
                searchItem: {
                    companyGrade: null,
                    companyName: '',
                    companyType: null,
                    contact: '',
                    pageIndex: 1,
                    pageSize: 16
                },
                type: '',
                tableData: [],
                contract: '',
                customerType: '',
                classes: [
                    {
                        value: 0,
                        label: 'A'
                    },
                    {
                        value: 1,
                        label: 'B'
                    },
                    {
                        value: 2,
                        label: 'C'
                    },
                    {
                        value: 3,
                        label: 'D'
                    },
                    {
                        value: 4,
                        label: 'S'
                    },
                ],
                types: [
                    {
                        value: 0,
                        label: '建机厂'
                    },
                    {
                        value: 1,
                        label: '国企'
                    },
                    {
                        value: 2,
                        label: '租赁商'
                    },
                    {
                        value: 3,
                        label: '代理商'
                    },
                    {
                        value: 4,
                        label: '集团公司'
                    },
                ],
                customerForm: {
                    id: null,
                    userId: userId,
                    companyName: '',
                    companyType: '',
                    contact: '',
                    companyGrade: '',
                    projectName: '',
                    phone: '',
                    parent: '',
                    address: ''
                },
                rules: {
                    companyName: [
                        {required: true, message: '客户名称不能为空', trigger: 'blur'}
                    ],
                    contact: [
                        {required: true, message: '联系人不能为空', trigger: 'blur'}
                    ],
                    phone: [
                        {required: true, message: '电话号码不能为空', trigger: 'blur'},
                        {type: 'number', message: '电话号码只能是数字'}
                    ],
                },
                title: '',
                value: true,
                selectClass: '',
                selectType: '',
                dialogVisible: false
            }
        },
        methods: {
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageCustomer(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageCustomer(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            searchBtu() {
                this.pageChange(1)
            },
            reset() {
                this.searchItem = {
                    customerClass: null,
                    customerName: '',
                    customerType: null,
                    contact: '',
                    pageIndex: 1,
                    pageSize: 16
                }
                this.pageChange(1)
            },
            addCustomer() {
                this.title = '新增客户'
                this.type = 'save'
                this.dialogVisible = true;
                this.customerForm = {
                    id: null,
                    userId: userId,
                    companyName: '',
                    companyType: 0,
                    contact: '',
                    companyGrade: 0,
                    phone: null,
                    parent: '',
                    projectName: '',
                    address: ''
                };
            },
            editCustomer(id) {
                this.dialogVisible = true;
                this.title = '编辑客户';
                this.type = 'edit';
                this.$api.getCustomerById({id:id}).then(res =>{
                    if (res.code == 200){
                        this.customerForm = {
                            id: res.data.id,
                            companyName: res.data.companyName,
                            companyType: res.data.companyType,
                            contact: res.data.contact,
                            companyGrade: res.data.companyGrade,
                            phone: res.data.phone * 1,
                            parent: res.data.parent,
                            projectName: res.data.projectName,
                            address: res.data.address
                        };
                    }
                })
            },
            deleteCustomer(id) {
                this.$confirm('你确定要删除客户信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteCustomer(id).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功'
                            })
                            this.pageChange("refresh");
                        } else {
                            this.$message.error('删除失败，请联系管理员')
                        }
                    })
                }).catch(() => {
                })
            },
            chooseSelection(val) {
                this.multipleSelection = val;
            },
            saveCustomer(formName, type) {
                this.customerForm.phone = this.customerForm.phone * 1
                if (this.customerForm.projectName == '') {
                    this.customerForm.projectName = '暂无'
                }
                if (type == "save") {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.$api.saveCustomer(
                                this.customerForm
                            ).then(res => {
                                if (res.code == 200) {
                                    this.$message.success("新增成功")
                                    this.pageChange("refresh");
                                    this.dialogVisible = false;
                                } else {
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    });
                } else {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.$api.updateCustomer(
                                this.customerForm
                            ).then(res => {
                                if (res.code == 200) {
                                    this.$message.success("修改成功")
                                    this.pageChange("refresh");
                                    this.dialogVisible = false;
                                } else {
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    });
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        },
        created() {
            this.searchBtu();
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconbianji {
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 5%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
        margin-left: 10px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 245px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

</style>
